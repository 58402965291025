import React from "react";
import HistoryMain from "../../modules/UploadMprReport/Component/History/HistoryMain";
import FormMain from "./Component/Form/FormMain";

const UploadMprReportMain = () => {
  return (
    <div className="md:w-10/12 lg:w-full w-full h-full md:h-full p-2 md:ml-[16.66%] mt-[50px] md:mt-[9%] lg:mt-[5%] overscroll-none">
      <div className="rounded-[30px] bg-cardBg h-full px-8 py-2 flex flex-col space-y-2 md:space-y-0">
        <div className="text-dashboardText test-sm font-medium ">
          Upload MPR Report
        </div>
        <div>
        <FormMain />
        </div>
       
        <div className="w-full flex-1 py-2">
          <HistoryMain />
        </div>
      </div>
    </div>
  );
};

export default UploadMprReportMain;
