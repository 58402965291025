import { UploadFormValidation, UploadMprReportFromProps } from "./module";
import { IUploadMprReportFromValue } from "./module";
import { useFormik } from "formik";
import React, { useState } from "react";
import CalenderFilter from "../../../../../components/ui/Common/Datepicker/CalenderFilter";
import { Button } from "../../../../../components/ui/button";
import { Loader2 } from "lucide-react";
import InputComp from "../../../../../components/ui/Common/InputsComponents/InputComp";
import { uploadMrpRepor } from "src/service/services";
import { useDispatch } from "react-redux";
import {
  updateError,
  updateErrorCode,
  updateSuccessMess,
} from "src/redux/Slicer/errorHadlingSlicer";
import { ENUM_STATUS_CODE } from "../../../../../helpers";
import axios, { AxiosError } from "axios";
import CalenderFrom from "../../../../../components/ui/Common/Datepicker/CalenderFrom";

const UploadForm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const formik = useFormik<UploadMprReportFromProps>({
    initialValues: IUploadMprReportFromValue,
    onSubmit: (values, { resetForm, setFieldValue }) => {
      const poastData = {
        ...values,
      };

      handleFormData(poastData);
    },
    validationSchema: UploadFormValidation,
  });
  const dispatch = useDispatch();
  const handleFormData = async (postData: UploadMprReportFromProps) => {
    setLoading(true);
    try {
      let apiResp = await uploadMrpRepor(postData);
      dispatch(updateErrorCode(apiResp.code));
      if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
        formik.resetForm();
        dispatch(updateSuccessMess(apiResp?.msg));
      } else {
        dispatch(updateError(apiResp.msg));
        dispatch(updateErrorCode(apiResp.code));
        // toast.error(apiResp.message)
      }
    } catch (err) {
      let error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        dispatch(updateError(error.response?.data.msg));
        dispatch(updateErrorCode(error.response?.data.code));
        // toast.error(error.response?.data.message)
      } else {
        dispatch(updateError(error.message));
        // toast.error(error.message)
      }
    } finally {
      setLoading(false);
    }
  };

  console.log(formik.values);
  return (
    <div className="w-full">
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full lg:w-3/4 grid grid-cols-1 md:grid-cols-2 md:gap-4 mx-auto">
          <InputComp
            errorMessage={
              formik.touched?.pdf_url && formik.errors?.pdf_url
                ? formik.errors?.pdf_url
                : ""
            }
            value={formik?.values?.pdf_url}
            onChange={(val: any) =>
              formik.setFieldValue("pdf_url", val?.target?.value)
            }
            type="text"
            id="pdf_url"
            lable="Enter Pdf Url"
            placeholder="Enter Pdf Url"
            lableClass="required"
          />

          <div className="">
            <CalenderFrom
              value={formik.values.dt}
              errorMessage={
                formik.touched?.dt && formik.errors?.dt ? formik.errors?.dt : ""
              }
              id="uploadMprDate"
              onchange={(e:string) => formik.setFieldValue("dt", e)}
              lableClass="required"
              lable="Date"
            />
          </div>
        </div>

        <div className="w-full flex justify-center items-center mt-4">
          <Button
            className="lg:w-1/5 w-full bg-goBackground gap-x-4 hover:bg-goBackground rounded-full text-xs font-semibold"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <>
                {" "}
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />{" "}
                <span>Please Wait...</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UploadForm;
