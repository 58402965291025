import { Popover, PopoverTrigger } from "@radix-ui/react-popover";
import { CalendarIcon } from "lucide-react";


import { useEffect, useState } from "react";

import { cn } from "src/helpers";

import dayjs from "dayjs";
import { Button } from "../../button";
import { PopoverContent } from "../../popover";
import { Calendar } from "../../calendar";
interface CalenderFilterProps {
  dts: any;
  // setDate:(dt:Date)=>void,
  onchange: (dt: any) => void;
  toDate?:any
}

const CalenderFilter_2 = ({ dts, onchange,toDate }: CalenderFilterProps) => {
  // console.log(dts)
  const [date, setDate] = useState<Date>();
  const [open,setOpen]=useState(false)
  const [datestring, setDateString] = useState("");
  const handleValueChange = (selectedValue: any) => {
    setDate(selectedValue);
    if (onchange) {
    
      onchange(dayjs(new Date(selectedValue)).format("YYYY-MM-DD"));
      setOpen(false)
    }
  };

  useEffect(() => {
    // if (dts) {
      let [year, month, day]: any = dts && dts?.toString().split("-");
      setDate(new Date(dts));
    // }
  }, [dts]);

  useEffect(() => {
    const isValid = dayjs(date, "YYYY-MM-DD", true).isValid(); 
    let dt = date && isValid? dayjs(new Date(date)).format("DD MMM,YYYY dddd")
      : "Pick a date";
    setDateString(dt);
  }, [date]);

  return (
    <Popover open={open} onOpenChange={(e:boolean)=>setOpen(e)}>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-full justify-between text-left font-normal bg-white rounded-full border border-input border-[#C6C6C6]",
            !date && "text-muted-foreground"
        )}
        >
          <span className="text-xs font-bold text-grayLable">
            {datestring}
          </span>
          <CalendarIcon className="mr-2 h-4 w-4 text-grayLable" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto  z-99999">
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleValueChange}
          initialFocus
          toDate={toDate}
        />
      </PopoverContent>
    </Popover>
  );
};
export default CalenderFilter_2;
