import * as Yup from "yup";

export interface UploadMprReportFromProps{
    pdf_url:string,
    dt:string
}

export const IUploadMprReportFromValue:UploadMprReportFromProps={
dt:"",
pdf_url:""
}

export const UploadFormValidation = Yup.object().shape({
  
dt:Yup.string().required("Date is required"),
pdf_url:Yup.string().required('Url is required')
  });
