import Action from "../Component/Action";
import { ColumnDef } from "@tanstack/react-table";
import { Check, X } from "lucide-react";

export type MprHistoryTable = {
  id: number;
  pdf_url: string;
  dt: string;
  created_at: string;
  updated_at: string;
  sr_no: number;
};

export const column: ColumnDef<MprHistoryTable>[] = [
  {
    accessorKey: "sr_no",
    header: "Sr_No.",
  },
  {
    accessorKey: "pdf_url",
    header: "Url",
  },
  {
    accessorKey: "dt",
    header: "Date",
  },
  {
    id: "action",
    header: "Download Report",
    cell({ row: { original } }) {
      return <Action {...original} />;
    },
  },
];
