import React, { useEffect, useMemo, useState } from "react";
import {
  useQueryResponse,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "../History/Context/Core/QueryResponseProvider";
import { QueryRequestProvider } from "../History/Context/Core/QueryRequestProvider";
import { QueryResponseProvider } from "../History/Context/Core/QueryResponseProvider";
import { MprReportMainState } from "../History/Context";
import Tables from "../History/Component/Tables";
import { column } from "../History/Component/column";
import CalenderFilter_2 from "../../../../components/ui/Common/Datepicker/CalenderFilter_2";

const Main = () => {
  const [date, setDate] = useState("");
  const { response }: any = useQueryResponse();
  const isLoading = useQueryResponseLoading();
  const apiResponse: any = useMemo(() => response, [response]);
  const pagination = useQueryResponsePagination();
  const { state, updateState } = MprReportMainState();
  const tableData: any = useMemo(
    () =>
      isLoading
        ? []
        : Array.isArray(apiResponse?.data?.data) &&
          apiResponse?.data?.data?.length > 0
        ? apiResponse?.data?.data.map((cur: any, index: number) => ({
            sr_no:
              index +
              1 +
              pagination.items_per_page *
                (pagination.page == 1 ? 0 : pagination.page - 1),
            ...cur,
          }))
        : [],
    [isLoading, apiResponse]
  );

  return (
    <div className="w-full h-full">
      <div className="w-full md:flex md:justify-between md:items-center space-y-2 md:space-y-0">
        <div className="text-dashboardText test-sm font-medium ">
          Upload MPR Report History
        </div>
        <div className="w-[250px] md:w-[300px]">
          <CalenderFilter_2
            dts={date}
            onchange={(e: string) => {
              setDate(e);
              updateState({
                filter: {
                  dt: e,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="w-full felx-1 ">
      <Tables data={tableData || []} columns={column} />
      </div>
     
    </div>
  );
};
const HistoryMain = ({}) => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <Main />
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  );
};
export default HistoryMain;
