
import React, { useMemo, useState } from 'react'
import ReportTable from '../Context/ReportCom/ReportTable'
import { reportColumn } from '../Context/ReportCom/reportColumn'

import { Button } from 'src/components/ui/button'
import VillageMain from 'src/components/ui/Common/Filter/Village/VillageMain'
import NamesMain from 'src/components/ui/Common/Filter/Names/NamesMain'
import DivisionMain from 'src/components/ui/Common/Filter/Division/DivisionMain'
import PhyProgressMain from 'src/components/ui/Common/Filter/PhysicalProgress/PhyProgressMain'
import FinicialProgressMains from 'src/components/ui/Common/Filter/FinicialProgress/FinicialProgressMains'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { dropDownReducerState } from 'src/redux/Slicer/dropdownSlicer'
import DistrictMain from 'src/components/ui/Common/Filter/District/DistrictMain'
import { useQueryResponseLoading, userReportMainState } from '../Context/index'
import { useQueryResponse } from '../Context/Core/QueryResponseProvider'
import { Link } from 'react-router-dom'
import CalenderComp from 'src/components/ui/Common/CalenderComp'
import dayjs from 'dayjs'
import { VIEW_PDF } from 'src/service/apis'
import { ChevronRight } from 'lucide-react'
const Report = () => {
  const { villageState, userName, division, phyProgres, finacialProgress, districtState } = useSelector((state: RootState) => dropDownReducerState(state))

  let dates = new Date()
  let initalDate = dayjs(dates).format("YYYY-MM-DD")
  const [futDate, setFutureDate] = useState<string>(initalDate)
  const { response }: any = useQueryResponse();
  const isLoading = useQueryResponseLoading();
  const apiResponse: any = useMemo(() => response, [response]);

  const tableData: any = useMemo(
    () => (isLoading ? [] : apiResponse ? apiResponse?.data : []),
    [isLoading, apiResponse]
  );

  const { state, updateState } = userReportMainState();

  const handleChange = () => {
    updateState({
      filter: {
        scheme_name: districtState,
        division: division,
        name_village: villageState,
        physical_progress: phyProgres,
        financial_progress: finacialProgress,
        name: userName
      }
    })
  }
  // console.log(futDate)
  return (
    <div className='w-full p-2 mt-10 lg:mt-0 h-full md:h-max'>
      <div className='w-full px-8 py-2 bg-cardBg rounded-[30px]  md:rounded-[30px]  h-full  lg:my-0 overflow-y-scroll md:overflow-y-hidden'>
        <div className='w-full grid grid-cols-1 lg:grid-cols-4 gap-x-4 gap-y-2 lg:gap-y-2  md:grid-cols-2 my-3  '>
          <DivisionMain />
          <DistrictMain />
          <VillageMain />
          <NamesMain />
          <PhyProgressMain />
          <FinicialProgressMains />

          <div className='w-full flex justify-end'>
            <Button className='md:mt-6 w-full bg-goBackground gap-x-4 hover:bg-goBackground rounded-full text-xs font-semibold'
              onClick={handleChange} disabled={isLoading}>GO <ChevronRight size={16} /></Button>
          </div>
        </div>

        <div className='w-full'>
          <ReportTable columns={reportColumn} data={tableData || []} />
        </div>

      </div>

    </div>
  )
}

export default Report