import React from "react";
import { MprHistoryTable } from "./column";

import { Button } from "../../../../../components/ui/button";
import { reportDownloadWithoutDivision } from "src/service/services";
import { Link } from "react-router-dom";
const Action = ({ pdf_url }: MprHistoryTable) => {
  return (
    <div className="p-1 flex justify-center">
      <Link
        to={pdf_url}
        target="_blank"
        className="border border-blue h-8 p-2 rounded-md text-xs font-medium font-Montserrat text-blue hover:text-blue"
      >
        Download
      </Link>
    </div>
  );
};

export default Action;
