import React, { InputHTMLAttributes } from 'react'
import { Input } from 'src/components/ui/input'
import { Label } from 'src/components/ui/label'
import CalenderFilter from './CalenderFilter_2'
interface InputsComp  {

  lable?: string
  lableClass?: string,
  errorMessage?: string
  cls?: string
id:string
  onchange: (e: any) => void
  value?: any;

}
const CalenderFrom: React.FC<InputsComp> = ({ lable, lableClass, errorMessage, cls, onchange, value, id}) => {
  
  return (
    <div className='w-full my-2'>
      
      <Label htmlFor={id} className={`text-legent text-sm font-semibold ml-2  ${lableClass}`}>{lable}</Label>
      <CalenderFilter dts={value||""} onchange={(e:string)=>onchange(e)} />
      <p className='text-xs text-red-600 ml-2 font-normal'>{errorMessage}</p>
    </div>
  )
}

export default CalenderFrom

