

import React from 'react'
import UploadForm from '../Form/Component/UploadForm'
const FormMain = () => {
  return (
    <div className='w-full h-full'>
        <UploadForm/>
    </div>
  )
}

export default FormMain